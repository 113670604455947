import React from "react";
import { useState, useEffect } from "react";
import { IoArrowBack } from "react-icons/io5";
import StarIcon from "../../icons/transparentstar.png";
import StarIcon2 from "../../icons/star.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { follow, unfollow, ProfilechangeStatus } from "../../app/features/profile/profile";

let followers = 0;

let follow_text = "follow";

const ModelByIdNav = ({modelName }) => {
  const dispatch = useDispatch();
const navigate = useNavigate()
   
     const follow_stats = useSelector((state) => state.profile.follow_stats);
      const unfollow_stats = useSelector((state) => state.profile.unfollow_stats);
  
      const userid = useSelector((state) => state.register.userID);
    const token = useSelector((state) => state.register.refreshtoken);
    const profile = useSelector((state) => state.comprofile.profile);
      const { postuserid } = useParams();
  const [followimg, setfollowimg] = useState(StarIcon);
  const [isfollwed, setisfollowed] = useState(false);
    const [disabledButton, setdisableButton] = useState(false);
    
 useEffect(() => {
    if (userid) {
      if (profile.following) {
          setisfollowed(true);
          
        setfollowimg(StarIcon2);
        follow_text = "";
      }
  
      if (!userid || userid === profile.userid) {
        setdisableButton(true);
      } else {
        setdisableButton(false);
      }
    }
  }, [userid, profile]);
    
  const follow_button = () => {
    if (isfollwed === true) {
      if (unfollow_stats !== "loading") {
          setfollowimg(StarIcon);
          setisfollowed(false);
        follow_text = "follow";
        dispatch(unfollow({ userid: postuserid, followerid: userid, token }));
      }
    } else if (isfollwed === false) {
      if (follow_stats !== "loading") {
        setfollowimg(StarIcon2);
        setisfollowed(true)
        follow_text = "";
        dispatch(follow({ userid: postuserid, followerid: userid, token }));
      }
    }
  };

  return (
    <div className="w-full mx-auto md:w-4/5 text-white  md:mr-auto md:ml-0  flex items-center justify-between sticky top-0 z-30 bg-black bg-opacity-80 ">
      <div onClick={() => navigate(-1)}>
        <IoArrowBack className="w-5 h-5"/>
          </div>
          <div className="flex items-center flex-row gap-2">
              
          <div className="text-right">
                  <p className="font-semibold">{ modelName}</p>
              <p>1050 clicks</p>
          </div>
      <div className="flex flex-row bg-orange-500 p-1 py-3  rounded-lg">
        <img src={followimg} className="w-5 h-5" alt="rating" />

        <button
          className=" p-0 px-2 rounded-lg"
          disabled={disabledButton}
          onClick={(e) => follow_button()}
        >
          {follow_text}
        </button>
      </div>
          </div>
    </div>
  );
};

export default ModelByIdNav;
