import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Gennavigation } from "../../navs/Gennav";

const Community = () => {
  const navigate = useNavigate();

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">

    <div className="chat_nav"><Gennavigation/></div>
      <div className="w-full md:w-2/4 flex flex-col text-gray-400 px-4 md:px-0">
        <header className="flex gap-4 items-center mb-4">
          <FaAngleLeft
            color="white"
            size={30}
            onClick={() => navigate(-1)}
            className="cursor-pointer"
          />
          <h4 className="font-bold text-lg text-white">Community Guidelines</h4>
        </header>
          <p className="text-gray-500 mb-6">Updated:15th March, 2025</p>
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-300">The Mmeko Community</h2>
          <p className="mt-2 text-gray-400">
            Mmeko strives to be a community in which all Users can express
            themselves openly and in which they can engage in free, open
            communications about whatever subjects they choose. The Platform is
            provided to showcase content and creativity of all kinds so that
            Users can enjoy diverse and wide-ranging material. However, in order
            to make that possible, it is necessary for all Users to conduct
            themselves in a way that is respectful, honest and fair.
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              These rules apply to using any Service offered on or via the
              Platform. By using the Platform, you are agreeing and undertaking
              to respect both the letter and spirit of these rules..
            </li>
            <li>
              We may change these Community Guidelines at any time without
              notice. Any such changes shall take effect on the next occasion
              you use the Platform. Any such new rules may be displayed
              on-screen when you next use the Platform, and you may be required
              to read and accept them to continue using the Platform. However,
              you are responsible for checking these Community Guidelines before
              using the Platform and making yourself aware of any changes. For
              ease of reference, the top of these guidelines indicates the date
              they were last updated.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-300">
            Content Guidelines
          </h2>
          <p className="mt-2 text-gray-400">
            Users must have the legal right to post any User Content they upload
            to the Platform, either by being the owner of the relevant rights or
            possessing a valid licence which entitles them to upload that
            content to the Platform.
          </p>
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            User Content must not:
          </h2>

          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              infringe or violate the Intellectual Property Rights of any third
              party, including other Users.
            </li>
            <li>
              violate the local laws of the jurisdiction from which the relevant
              User is accessing the Platform.
            </li>
            <li>
              incite or encourage other Users (or anyone else) to engage in
              activity which would be unlawful in your or their jurisdictions.
            </li>
            <li>
              be a reposting or a replication of any other User Content which we
              have declared to be in breach of the Community Guidelines (or any
              other part of our terms)
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            Hate Speech
          </h2>
          <p className="mt-2 text-gray-400">
            Members of the Mmeko community must communicate with each other
            respectfully and politely. Any form of harassment, hate speech, or
            discrimination is strictly prohibited. We encourage open and honest
            communication, but it must be conducted respectfully that does not
            infringe on the rights of others. If you disagree with someone,
            please do so respectfully.
          </p>

          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              We encourage free speech and recognise that some opinions and
              viewpoints may cause upset or offend others. However, we will not
              in any circumstances tolerate speech which incites, encourages or
              expresses hatred to or against any ethnic, religious, gender, or
              other groups which share an identity characteristic.
            </li>
            <li>
              You must not post or upload hateful User Content. There are no
              exceptions to this rule.
            </li>
            <li>
              Your User Content is likely to be considered hateful if it
              encourages or wishes for violence/physical harm to a particular
              group of people, encourages for exclusion or segregation of a
              particular group of people, or if it contains slurs that are
              intended to degrade or dehumanize a particular group of people.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            Terrorism and Extremism
          </h2>
          <p className="mt-2 text-gray-400">
            The Platform expressly prohibits any content which glorifies,
            encourages, or seeks to recruit individuals to participate in
            terrorism or other extremist activity. There are no exceptions to
            this rule.{" "}
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            Non-Consensual and Unsolicited Sexual Material
          </h2>
          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              Any sexual material which depicts images of sexual activity in
              which one or more participants are unwilling or unconsenting is
              prohibited. You must not upload it to the Platform.
            </li>
            <li>
              - This includes sexual material in which participants are
              unwillingly involved, including intimate photography/video of
              individuals who were unaware they were being photographed/filmed
              or unwilling to participate.
            </li>
            <li>
              You must not send User Content which is sexual in nature to other
              Users who have not indicated that they wish to receive it from you
              by, for example, subscribing to your User Content or through
              direct communications. This includes intimate photography of
              yourself.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            AI-Generated Media
          </h2>
          <p className="mt-2 text-gray-400">
            Mmeko is open to and supportive of creative users who wish to
            utilise AI-generated media. However, to ensure a respectful and safe
            community, Mmeko has specific guidelines for uploading, posting, and
            sharing AI-generated media. This includes but is not limited to
            deepfakes, generated images, and other types of synthetic media.{" "}
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              <strong className="text-gray-300">Disclosure:</strong> If you are uploading, posting or
              sharing AI-generated media on Mmeko, you must disclose that it is
              not real and was generated using AI. This disclosure must be clear
              and prominent so that other community members know the media is
              not authentic. This may be displayed on the profile bio, content
              caption, or as a watermark on the media itself. Failure to
              properly disclose may result in your content being removed and/or
              your account being suspended.
            </li>
            <li>
              <strong className="text-gray-300">Nature:</strong> AI-generated media must not be used to
              create harmful or misleading content. Any content that is intended
              to deceive or harm others is strictly prohibited.
            </li>
            <li>
              <strong   className="text-gray-300">Moderation:</strong> All content must pass Mmeko's
              "Reasonable Person's Test," conducted by three or more members of
              Mmeko's moderation team. The "Reasonable Person's Test" is
              designed to assess if a piece of AI content complies with Mmeko's
              rules and policies. A failed test will result in the content being
              removed, multiple failures may result in your account being
              permanently suspended."
            </li>
            <li>
              <strong className="text-gray-300">Age and Appearance:</strong> If you are uploading
              AI-generated media, you must disclose that it is AI-generated.
            </li>
            <li>
              <strong className="text-gray-300">Compliance:</strong> - All AI-generated media must be
              respectful and not violate any of the community guidelines. This
              includes but is not limited to avoiding harassment, hate speech,
              or discrimination.
            </li>
            <li>
              <strong className="text-gray-300">Copyright:</strong> AI-generated media must not infringe
              on any copyright or ownership rights. Uploading such content may
              result in legal consequences, and the uploader is responsible for
              ensuring they have the right to use and share the media. Media
              created by “Deepfake” Editing is illegal, without the permission
              or granted rights from the original content creator.
            </li>
            <li>
              <strong className="text-gray-300">Consequences:</strong> Violating these guidelines will
              result in appropriate consequences, determined based on the
              severity of the violation, and may include a warning, removal,
              suspension, or permanent ban from the community.
            </li>
          </ul>
          <p>
            {" "}
            Deepfaked content, including all forms of media, such as
            “faceswapping” is strictly prohibited from Mmeko without the
            appropriate proof of copywrite permission, consent documents, and
            KYC ID documents of the original content author. Uploading and/or
            distributing media, reasonably determined as a deepfake, is
            considered illegal and will be removed from Mmeko and may result in
            your account being permanently suspended, unless we have first
            verified the content and account.{" "}
          </p>

          <p>
            We retain the right to remove any content without notice if it
            breaks the community guidelines or Fanvue policies. This includes
            but is not limited to AI-generated media that violates any of the
            guidelines outlined above.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            Explicit Content{" "}
          </h2>
          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              Explicitly sexual content is prohibited on the Platform Unless it
              is Flagged as "Exclusive Content" whereby Only those that bought
              the Exclusive Content will be able to see it. In addition, you
              must not make explicit User Content available to Users, as a Paid
              for Service or otherwise, if you are aware, or ought reasonably to
              be aware, such Users are under the age of 18.
            </li>
            <li>
              Explicit User Content depicting or otherwise involving anyone
              under 18, including yourself, is prohibited. It is your
              responsibility to ensure that the subjects of your explicit User
              Content are over the age of 18 and to maintain appropriate
              documentation sufficient to evidence the same.
            </li>
            <li>
              User Content is likely to be considered explicit if it could be
              considered inappropriate for people under the age of 18; it
              contains material which is sexual or pornographic; or it depicts
              injury or violence in a way that is graphic, gratuitous, obscene
              or excessive.
            </li>
            <li>
              Context can be important in making a determination. In particular,
              content representing factual reporting on current events is less
              likely to be considered explicit when it consists of newsworthy
              images that help individuals understand the events being reported
              on properly. Likewise, images representing artistic or stylised
              representations of these subjects are less likely to be considered
              explicit by us.
            </li>
          </ul>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            Private Information{" "}
          </h2>
          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              You must not disclose or publish private information about others
              who have not consented to that publication. In particular, this
              includes publishing the name, address, or occupation of other
              Users who have not chosen to make that information about
              themselves public.
            </li>
          </ul>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            Impersonation{" "}
          </h2>
          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              You must not use the Platform to impersonate other individuals in
              a way that is designed to mislead other Users. In particular, you
              must not use the Platform to impersonate other Users or public
              figures.
            </li>
            <li>
              ‘Parody’ or similar comedic use of the Platform is unlikely to
              infringe this rule. However, this kind of use must be clearly
              labelled and easily recognisable by its intended and likely
              audience.
            </li>
          </ul>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            Unlawful Activity{" "}
          </h2>
          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              You must not use the Platform to upload any unlawful content in
              your jurisdiction. For the avoidance of doubt, this includes
              content that solicits sales of goods/services which are unlawful
              in the jurisdiction of the buyer/seller, it includes content which
              solicits sexual activity with individuals below the age of
              consent, it includes content to designed to bully, harass,
              threaten or intimidate other individuals, and it includes content
              which promotes or encourages suicide or self-harm (including
              eating disorders).
            </li>
          </ul>
        </section>

        <p>
          Mmeko reserves the right to determine whether User Content has
          infringed any of the above restrictions. While we will consider
          information about User Content provided by Users, our judgement is
          final.
        </p>
        <p>
          While we reserve the right to remove, delete, suspend or edit any
          Content on the Platform for any reason and at any time, individual
          Users are responsible for all User Content on the Platform. Mmeko
          accepts no responsibility or liability for anything you or any other
          User may post or upload to the Platform. You must be willing to accept
          the legal consequences of your own speech and actions if you infringe
          on any applicable laws.
        </p>
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-300 mt-3">
            User Behaviour Guidelines
          </h2>
          <p>
            As a condition of being granted access to the Platform and the
            Services, you agree.
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-400">
            <li>
              not to submit or transmit any material, including User Content
              which is in breach of these Community Guidelines
            </li>
            <li>
              not to use the Platform or Service in any way to offer, solicit,
              arrange, promote, facilitate or engage in human trafficking, sex
              trafficking, child exploitation, physical abuse, violence,
              brutality, rape or any other non-consensual sexual behaviour and
              you agree that any active membership or participation in
              anti-human trafficking and / or anti-child exploitation groups is
              forbidden
            </li>
            <li>
              not to use the Platform or Service in an unlawful manner, for any
              unlawful purpose, in any manner inconsistent with these Community
              Guidelines, or act fraudulently or maliciously, for example, by
              attempting to gain unauthorised access to the Platform or the
              server on which the Platform is stored, by hacking into the
              Platform or by inserting malicious code, including viruses, or
              harmful data, into the Platform, any Service or any operating
              system
            </li>
            <li>
              not to use the Platform or Service to offer, solicit, arrange, or
              engage in any kind of activity or arrangement which is, or which
              would be unlawful, including, but not limited to, the promotion,
              distribution or advertisement of escort services, illegal drugs
              (or illegal drug paraphernalia), drug manufacturing techniques,
              illegal firearms or other illegal weapons
            </li>
            <li>
              not to infringe our rights or those of any third party in relation
              to your use of the Platform or any Service, including, without a
              limitation, a person’s Intellectual Property Rights, contractual
              rights, confidentiality rights, human rights, rights to privacy
              and other rights under applicable law
            </li>
          </ul>
              </section>
              <p>In conclusion, community guidelines are essential in creating a safe and inclusive environment where members can learn, grow, and collaborate. Every community member must familiarize themselves with the guidelines and work together to ensure they are followed.</p>
              <p className="mb-16">By following these guidelines, we can ensure that Mmeko remains a safe and respectful community for all members, regardless of the type of media being shared.</p>
      </div>
    </div>
  );
};

export default Community;
