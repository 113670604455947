import React, { useEffect, useState } from "react";
import optionicon from "../../icons/editcommenticon.svg";
import editIcon from "../../icons/edit.svg";
import deleteicon from "../../icons/deleteicon.svg";
import PacmanLoader from "react-spinners/PacmanLoader";
import PacmanLoader1 from "react-spinners/ClockLoader";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-awesome-slider/dist/styles.css";
import { Bookinginfo } from "../../fragment/bookingFrag/Bookinginfo";
import { Bookingsuccess } from "../../fragment/bookingFrag/Bookingsuccess";
import { Requestform } from "../../fragment/bookingFrag/Requestform";
import closeIcon from "../../icons/closeIcon.svg";
import { getreview } from "../../app/features/model/modelSlice";
import { Model_review } from "./Model_review";

import { useSelector, useDispatch } from "react-redux";
import {
  getmymodelbyid,
  changemodelstatus,
  deletemodel,
} from "../../app/features/model/modelSlice";
import { downloadImage } from "../../api/sendImage";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { addcrush, remove_Crush } from "../../app/features/model/modelSlice";
import { useToast } from "../../components/toast";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Gennavigation } from "../../navs/Gennav";
import ModelByIdNav from './ModelByIdNav'

//import addcrush({inputs  : modelid and userid})
//userid : the current user ID that wish to add the model to its crush list
//modelid : the model ID that this user wishes to add to its crush list

//method stats and api message for redux selectors
// addcrush_stats and addcrush_message

var taken = true;
let bookinfo = ""
export const Modelbyid = () => {
  const { modelID } = useParams();
  const Model = modelID.split(",");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.register.userID);
  const Mymodel = useSelector((state) => state.profile.modelID);
  const login = useSelector((state) => state.register.logedin);
  const token = useSelector((state) => state.register.refreshtoken);
  const message = useSelector((state) => state.model.message);
  const modelbyidstatus = useSelector((state) => state.model.modelbyidstatus);
  const getreviewstats = useSelector((state) => state.model.getreviewstats);
  const modeldeletestatus = useSelector(
    (state) => state.model.modeldeletestatus
  );
  const reviewList = useSelector((state) => state.model.reviewList);
  const addcrush_stats = useSelector((state) => state.model.addcrush_stats);
  const addcrush_message = useSelector((state) => state.model.addcrush_message);
  const remove_crush_stats = useSelector((state) => state.model.remove_crush_stats);
  const remove_crush_message = useSelector((state) => state.model.remove_crush_message);
  const { successalert, dismissalert } = useToast();
  const model = useSelector((state) => state.model.modelbyid);
  const [showmode, setshowmodel] = useState(false);
  const [photocount, setphotocount] = useState();
  const [oldlink, setoldlink] = useState([]);
  const [documentlink, setdocumentlink] = useState([]);
  const [docCount, setdocCount] = useState();
  const [modelid, setmodelid] = useState([Model[1], userid]);
  const [bookingclick, setbookingclick] = useState(false);
  const [success, setsuccess] = useState(false);
  const [requested, setrequested] = useState(false);
  const [review_click, setreview_click] = useState(false);
  const [dcb, set_dcb] = useState(false);
  const [removeCrush, set_removeCrush] = useState(false)
  const [crush_text, set_crush_text] = useState("Add to Crush");
  const [hosttype , sethosttype] = useState("")

  const [closeOption, setcloseOption] = useState(false);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#d49115");
  let [modelmeet, setmodlemeet] = useState("")
  let [bookinfo,setbookinfo] = useState("")

  let [loading1, setLoading1] = useState(true);
  let [color1, setColor1] = useState("#d49115");
  const [click,setclick] = useState(true)
  const [imglist, setimglist] = useState([]);

  useEffect(() => {
    // if (!login) {
    //   window.location.href = "/";
    // }

    if (modelbyidstatus !== "loading") {
      dispatch(
        getmymodelbyid({
          hostid: Model[0],
          token,
          userid
        })
      );
    }

    if (modelbyidstatus !== "loading") {
      dispatch(
        getmymodelbyid({
          hostid: Model[0],
          token,
          userid,
        })
      );
    }

    if (getreviewstats !== "loading") {
      dispatch(
        getreview({
          modelid: Model[0],
          token,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (modelbyidstatus === "succeeded") {
      setLoading(false);
      setshowmodel(true);
      checkcrush();
      setstats()
      const linksimg = model.photolink.split(",");
      //const doclist = model.document.split(",");
      //setdocCount(doclist.length);
      setphotocount(linksimg.length);
      linksimg.forEach((index) => {
        let img = downloadImage(index, "model");

        setimglist((value) => [...value, img]);
        setoldlink((value) => [...value, index]);
        // if(imglist.length <= photocount){
        //     setimglist(value => [...value, img])
        //     setoldlink(value => [...value, index])

        // }
      });

      // doclist.forEach((index) => {
      //   setdocumentlink((value) => [...value, index]);
      // });

      dispatch(changemodelstatus("idle"));
    }

    if (modelbyidstatus === "failed") {
      toast.error(`${message}`, { autoClose: 2000 });
      dispatch(changemodelstatus("idle"));
    }
  }, [modelbyidstatus]);

  useEffect(() => {
    if (modeldeletestatus === "succeeded") {
      dispatch(changemodelstatus("idle"));
      setLoading(false);
      navigate("/model");
    }

    if (modeldeletestatus === "failed") {
      toast.error(`${message}`, { autoClose: 2000 });

      dispatch(changemodelstatus("idle"));
      setLoading(false);
    }
  }, [modeldeletestatus]);

  useEffect(() => {
    if (addcrush_stats === "succeeded") {
      dispatch(changemodelstatus("idle"));
      set_dcb(false);
      set_removeCrush(true)
      set_crush_text("Remove crush");
    }

    if (addcrush_stats === "failed") {
      dispatch(changemodelstatus("idle"));
      set_crush_text("Add to crush");
      set_dcb(false);
    }

     if (remove_crush_stats === "succeeded") {
      dispatch(changemodelstatus("idle"));
      set_dcb(false);
      set_removeCrush(false)
      set_crush_text("Add to crush");
    }

    if (addcrush_stats === "failed") {
      dispatch(changemodelstatus("idle"));
      set_crush_text("Remove crush");
      set_dcb(false);
      set_removeCrush(true)
    }
  }, [addcrush_stats, remove_crush_stats]);

  // const checkuser = ()=>{

  //     if(model.userid === userid){
  //         return true
  //     }else{
  //         return false
  //     }
  // }

  const checkcrush = () => {
    if (model.add) {
      set_dcb(false);
      set_crush_text("Remove crush");
      set_removeCrush(true)
    }
  };

  //   const checkimg = ()=>{

  //   if (getreviewstats !== "loading") {
  //     dispatch(
  //       getreview({
  //         modelid: Model[0],
  //         token,
  //       })
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (modelbyidstatus === "succeeded") {
      setLoading(false);
      setshowmodel(true);
    

      const linksimg = model.photolink.split(",");
      // const doclist = model.document.split(",");
      // setdocCount(doclist.length);
      setphotocount(linksimg.length);
      linksimg.forEach((index) => {
        let img = downloadImage(index, "model");

        setimglist((value) => [...value, img]);
        setoldlink((value) => [...value, index]);
        // if(imglist.length <= photocount){
        //     setimglist(value => [...value, img])
        //     setoldlink(value => [...value, index])

        // }
      });

      // doclist.forEach((index) => {
      //   setdocumentlink((value) => [...value, index]);
      // });

      dispatch(changemodelstatus("idle"));
    }

    if (modelbyidstatus === "failed") {
      toast.error(`${message}`, { autoClose: 2000 });
      dispatch(changemodelstatus("idle"));
    }
  }, [modelbyidstatus]);

  useEffect(() => {
    if (modeldeletestatus === "succeeded") {
      dispatch(changemodelstatus("idle"));
      setLoading(false);
      navigate("/model");
    }

    if (modeldeletestatus === "failed") {
      toast.error(`${message}`, { autoClose: 2000 });

      dispatch(changemodelstatus("idle"));
      setLoading(false);
    }
  }, [modeldeletestatus]);

  const checkuser = () => {
    if(userid){
        if (model.userid === userid) {
      return true;
    } else {
      return false;
    }

    }else{
      return false
    }
  
  };

  const setstats = ()=>{
    if(model.hosttype === "fan Meet"){
      setmodlemeet("Meet and Greet with")
      
    }else if(model.hosttype === "Fan date"){
      setmodlemeet("A Date with")
      
    }else if(model.hosttype === "Private show"){
      setmodlemeet("A Private Conversation with")
      
    }
  }
  const checkimg = () => {
    if (loading === false) {
      if (imglist.length > 0) {
        return (
          <div className="mt-2 md:mt-16 mb-4 w-full  h-full min-w-80 flex flex-col justify-center items-center">
            <AwesomeSlider>
              {imglist.map((value) => {
                return (
                  <div className="h-full w-full  ">
                    <img
                      alt="host pics"
                      src={value}
                      className="object-cover h-full mx-auto  w-full"
                    ></img>
                  </div>
                );
              })}
            </AwesomeSlider>
          </div>
        );
      }
    }
  };

  const deleteModel = () => {
    if (modeldeletestatus !== "loading") {
      setLoading(true);
      dispatch(
        deletemodel({
          oldlink,
          documentlink,
          photocount,
          photolink: model.photolink,
          hostid: model.hostid,
          token,
          docCount,
        })
      );
    }
  };

  const Cantchat = () => {
    if (model.userid === userid) {
      return false;
    } else {
      return true;
    }
  };

  const Check_review = () => {
    setreview_click(true);
    if (getreviewstats === "loading") {
      setLoading1(true);
    } else {
      setLoading1(false);
    }
  };

  const display_review = () => {
    if (loading1 === true) {
      return false;
    } else {
      return true;
    }
  };

  const show_review = () => {
    if (loading1 === false) {
      if (reviewList.length > 0) {
        return reviewList.map((value) => {
          return (
            <Model_review
              content={value.content}
              name={value.name}
              photolink={value.photolink}
              posttime={value.posttime}
              id={value.id}
              userid={value.userid}
            />
          );
        });
      } else {
        return (
          <div className="w-full flex justify-center">
            <p className="text-slate-300 text-sm">This model got 0 reviews</p>
          </div>
        );
      }
    }
  };

  const addTocrush = () => {
    if (addcrush_stats !== "loading" && removeCrush === false) {
      set_dcb(true);
      set_crush_text("adding to crush list...");
      dispatch(addcrush({ userid, token, modelid: model.hostid }));
     // successalert(addcrush_message, "success", 5000);
    }

    if(remove_crush_stats !== "loading" && removeCrush === true){
      set_dcb(true);
      set_crush_text("removing crush from list...");
      dispatch(remove_Crush({ userid, token, modelid: model.hostid }));
    }
  };


  const checkOnline = ()=>{
    if(model.active){
      return "online"
    }else{
      return "offline"
    }
  }

  

  return (
    <div className='flex flex:col justify-center items-center  md:mr-auto md:ml-8'>
      <div className="chat_nav"><Gennavigation /></div>
      {loading && (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <div className="w-full p-4 space-y-4">
           
            <div className="flex justify-center">
              <Skeleton width={300} height={300} className="rounded-lg" />
            </div>
    
            
            <div className="flex justify-between px-2">
              <Skeleton width={170} height={30} className="rounded-md" />
              <Skeleton width={170} height={30} className="rounded-md" />
            </div>
    
            <div className="flex justify-center">
              <Skeleton width={380} height={30} className="rounded-md" />
            </div>
    
        
            <div className="space-y-2 px-4">
              {Array(12)
                .fill(0)
                .map((_, index) => (
                  <div key={index} className="flex justify-between w-full">
                    <Skeleton width={100} height={20} />
                    <Skeleton width={140} height={20} />
                  </div>
                ))}
            </div>
    
           
          </div>
        </SkeletonTheme>
      )}


      {showmode && (
        <div className="p-2 pl-3 pr-3 sm:pl-11 md:w-1/2 overflow-auto pb-16 sm:ml-8 relative md:mt-5 md:mr-auto md:ml-8 " onClick={(e) => setclick(true)}>
          <div className='w-full'>

            <ModelByIdNav modelName={model.name.split(" ")[0] } />
          </div>
<div className="overflow-auto md:mt-24">
          <ToastContainer position="top-center" theme="dark" />
          <div className="flex justify-evenly">
            {/* <p
              className="text-slate-300 text-center"
              onClick={(e) => {
                navigate("/model");
              }}
            >
              Models
            </p>
            <p className="text-slate-300 text-center">{model.hosttype}</p>
            <p className="text-slate-300 text-center">{checkOnline()}</p> */}
            {checkuser() && (
              <button>
                <img
                  alt="optionicon"
                  src={optionicon}
                  onClick={(e) => {
                    setcloseOption(!closeOption);
                  }}
                ></img>
                {closeOption && (
                  <div className="flex flex-col z-10 text-left">
                    <button
                      onClick={(e) => {
                        navigate("/editmodel");
                      }} className="text-white"
                    >
                      {/* <img
                        alt="editicon"
                        src={editIcon}
                        className="w-4 h-4 object-cover mb-1"
                      ></img> */}
                      Edit
                    </button>
                    <button
                      onClick={(e) => {
                        deleteModel();
                      }} className="text-white "
                    >
                      {/* <img
                        alt="deleteIcon"
                        src={deleteicon}
                        className="w-4 h-4 object-cover"
                      ></img> */}
                      Delete
                    </button>
                  </div>
                )}
              </button>
            )}
          </div>

          <div className="visible scroll-pt-16 ">{checkimg()}</div>

          <div className="flex justify-between">
            {Cantchat() && (
              <button
                className="btn w-2/3 mr-1 bg-red-700 text-slate-100"
                onClick={(e) => {
                  if(!userid){
                    toast.info("login to access this operation",{autoClose:2000})
                    return
                  }
                  navigate(`/message/${modelid.toString()}`);
                }}
              >
                Message
              </button>
            )}

            {Cantchat() && (
              <button
                className="btn w-2/3 ml-1 bg-red-700 text-slate-100"
                onClick={(e)=>{
                   if(!userid){
                    toast.info("login to access this operation",{autoClose:2000})
                    return
                  }
                  addTocrush()
                }}
                disabled={dcb}
              >
               {crush_text}
              </button>
            )}
          </div>

          {Cantchat() && (
            <button
              className="btn w-full mt-2"
              onClick={(e) => {
                 if(!userid){
                    toast.info("login to access this operation",{autoClose:2000})
                    return
                  }
                setbookingclick(true);
              }}
            >
              Request {model.hosttype}
            </button>
          )}
          <div className="text-slate-300 font-semibold text-center mx-auto my-2">
            <p> {modelmeet} { model.name.split(" ")[0]}</p>
         </div>
          <div className="grid sm:grid-cols-2">
            <p className="text-slate-300  flex justify-between w-full sm:block"><span>Name :</span> <span> {model.name}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Age :</span> <span>{model.age}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Location :</span> <span>{model.location}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Tip :</span> <span>{model.price}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Duration :</span> <span>{model.duration}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Bodytype :</span> <span>{model.bodytype}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Smoke :</span> <span>{model.smoke}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Drink :</span> <span>{model.drink}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Interest :</span><span>{model.interestedin}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Height :</span> <span>{model.height}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Weight :</span> <span>{model.weight}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Gender :</span> <span>{model.gender}</span></p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>status :</span> <span>{model.verify}</span></p>
            <p className="text-slate-300   text-wrap break-all mr-2 flex justify-between w-full sm:block">
              <span className="whitespace-nowrap">Available Hours :</span> <span className=" max-w-[200px] overflow-x-auto whitespace-nowrap">{model.timeava}</span>
            </p>
            <p className="text-slate-300 flex justify-between w-full sm:block"><span>Available Days :</span> <span>{model.daysava}</span></p>
          </div>
          <div className="text-center text-slate-300 mt-1">
            <p className="text-slate-300 font-semibold">About me</p>
            <p className="text-gray-400 text-sm leading-relaxed  text-wrap break-all">
              {model.description}
            </p>
          </div>

          <button
            className="flex flex-col mx-auto text-gray-900 text-sm mb-16 bg-slate-300 p-2 rounded-md"
            onClick={(e) => {
               if(!userid){
                    toast.info("login to access this operation",{autoClose:2000})
                    return
                  }
              Check_review();
            }}
          >
            <p>view reviews</p>
            <p className="text-center mx-auto">{`${reviewList.length} review`}</p>
          </button>

          {review_click && (
            <div className="w-5/6 h-1/6 bg-slate-300 z-10 bottom-1/4 absolute rounded-md shadow shadow-slate-500">
              <div className="w-full flex justify-end">
                <button onClick={(e) => {
                  setreview_click(false)
                }}>
                  <img
                    alt="closeIcon"
                    src={closeIcon}
                    className="w-5 h-5 object-cover"
                  ></img>
                </button>
              </div>

              {loading1 && (
                <div className="h-full w-full flex flex-col justify-center items-center">
                  <PacmanLoader1
                    color={color1}
                    loading={loading1}
                    size={25}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <p className="text-sm text-slate-300">Please wait...</p>
                </div>
              )}

              {display_review() && (
                <div className="w-full h-full flex flex-col pl-3 pr-3 overflow-auto">
                  {show_review()}
                </div>
              )}
            </div>
          )}

          {bookingclick && (
            <div className="z-50 shadow-black shadow-2xl fixed left-20 top-20">
              <Bookinginfo
                setbookclick={setbookingclick}
                amount={model.price}
                setsuccess={setsuccess}
                type={model.hosttype}
              />
            </div>
          )}

          {success && (
            <div className="z-50 shadow-black shadow-2xl fixed left-20 top-20">
              <Requestform
                setsuccess={setsuccess}
                price={model.price}
                toast={toast}
                modelid={model.hostid}
                type={model.hosttype}
                setrequested={setrequested}
              />
            </div>
          )}

          {requested && (
            <div className="z-50 shadow-black shadow-2xl fixed left-20 top-20">
              <Bookingsuccess setrequested={setrequested} />
            </div>
          )}</div>
        </div>
      )}

      {/* {showmode && (
        <div className="p-2 pl-3 pr-3 sm:pl-11 sm:w-1/2 overflow-auto pb-16 sm:ml-8 relative">
          <ToastContainer position="top-center" theme="dark" />
          <div className="flex justify-evenly">
            <p
              className="text-slate-300 text-center"
              onClick={(e) => {
                navigate("/model");
              }}
            >
              Models
            </p>
            <p className="text-slate-300 text-center">{model.hosttype}</p>
            <p className="text-slate-300 text-center">Offline</p>
            {checkuser() && (
              <button>
                <img
                  alt="optionicon"
                  src={optionicon}
                  onClick={(e) => {
                    setcloseOption(!closeOption);
                  }}
                ></img>
                {closeOption && (
                  <div className="flex flex-col z-10 ">
                    <button
                      onClick={(e) => {
                        navigate("/editmodel");
                      }}
                    >
                      <img
                        alt="editicon"
                        src={editIcon}
                        className="w-4 h-4 object-cover mb-1"
                      ></img>
                    </button>
                    <button
                      onClick={(e) => {
                        deleteModel();
                      }}
                    >
                      <img
                        alt="deleteIcon"
                        src={deleteicon}
                        className="w-4 h-4 object-cover"
                      ></img>
                    </button>
                  </div>
                )}
              </button>
            )}
          </div>

          <div className="invisible sm:visible">{checkimg()}</div>

          <div className="flex justify-between">
            {Cantchat() && (
              <button
                className="btn w-2/3 mr-1 bg-red-700 text-slate-100"
                onClick={(e) => {
                  navigate(`/message/${modelid.toString()}`);
                }}
              >
                Message
              </button>
            )}

            {Cantchat() && (
              <button
                className="btn w-2/3 ml-1 bg-red-700 text-slate-100"
                disabled={dcb}
                onClick={(e) => {
                  addTocrush();
                }}
              >
                {crush_text}
              </button>
            )}
          </div>

          {Cantchat() && (
            <button
              className="btn w-full mt-2"
              onClick={(e) => {
                setbookingclick(true);
              }}
            >
              Request {model.hosttype}
            </button>
          )}

          <div className="grid sm:grid-cols-2">
            <p className="text-slate-300"> {model.name}</p>
            <p className="text-slate-300">Age : {model.age}</p>
            <p className="text-slate-300">Location : {model.location}</p>
            <p className="text-slate-300">Price : {model.price}</p>
            <p className="text-slate-300">Duration : {model.duration}</p>
            <p className="text-slate-300">Bodytype : {model.bodytype}</p>
            <p className="text-slate-300">Smoke : {model.smoke}</p>
            <p className="text-slate-300">Drink : {model.drink}</p>
            <p className="text-slate-300">Interest : {model.interestedin}</p>
            <p className="text-slate-300">Height : {model.height}</p>
            <p className="text-slate-300">Weight : {model.weight}</p>
            <p className="text-slate-300">Gender : {model.gender}</p>
            <p className="text-slate-300">status : {model.verify}</p>
            <p className="text-slate-300   text-wrap break-all mr-2">
              Available Hours :{model.timeava}
            </p>
            <p className="text-slate-300">Available Days : {model.daysava}</p>
          </div>
          <div className="text-center text-slate-300 mt-1">
            <p className="text-slate-300 font-semibold">Descreption</p>
            <p className="text-slate-300 text-sm leading-relaxed  text-wrap break-all">
              {model.description}
            </p>
          </div>

          <button
            className="flex flex-col mx-auto text-slate-300 text-sm mb-16 bg-slate-300 p-2 rounded-md"
            onClick={(e) => {
              Check_review();
            }}
          >
            <p>view reviews</p>
            <p className="text-center mx-auto">{`${reviewList.length} review`}</p>
          </button>

          {review_click && (
            <div className="w-5/6 h-1/6 bg-slate-300 z-10 bottom-1/4 absolute rounded-md shadow shadow-slate-500">
              <div className="w-full flex justify-end">
                <button onClick={(e) => setreview_click(false)}>
                  <img
                    alt="closeIcon"
                    src={closeIcon}
                    className="w-5 h-5 object-cover"
                  ></img>
                </button>
              </div>
            </div>
          )}
        </div>
      )} */}
    </div>
  );
};
