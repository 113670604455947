import React, { useEffect, useRef, useState } from "react";
import videocamIcon from "../../icons/videocamIcon.svg";
import emojsicon from "../../icons/addemojis.js.svg";
import sendIcon from "../../icons/sendIcon.svg";
import "emoji-picker-element";
import { Chatreply } from "./Chatreply";
import {Coinsendview} from "./List/Coinsendview"
import {
  getchat,
  changemessagestatus,
  updatemessage,
} from "../../app/features/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PacmanLoader from "react-spinners/DotLoader";
import { socket } from "../../api/config";
import { Model } from "../modelviews/Model";
import onlineIcon from "../../icons/onlineIcon.svg";
import offlineIcon from "../../icons/offlineIcon.svg";
import starIcon from "../../icons/star.png";
import dodoIcon from "../../icons/icons8-profile_Icon.png";
import Options from "../../icons/menu.svg";
import postimageIcon from "../../icons/postimageicon.svg";
import backIcon from "../../icons/backIcon.svg";
import goldIcon from "../../icons/icons8.png";
import { send_gift } from "../../app/features/message/messageSlice";
import { toast } from "react-toastify";
import { downloadImage } from "../../api/sendImage";
import DropdownMenu from "../../components/dropdown";
import {Gennavigation} from "../../navs/Gennav"
import "../../navs/Navs.css"

let mychat = "yes";
let messageType = "";
let ClientMess

export const Chat = () => {
  const msgListref = useRef(null);
  const messagestatus = useSelector(
    (state) => state.message.currentmessagestatus
  );
  const giftstats = useSelector((state) => state.message.giftstats);
  const giftmessage = useSelector((state) => state.message.giftmessage);
  const oldMessage = useSelector((state) => state.message.listofcurrentmessage);
  const chatinfo = useSelector((state) => state.message.chatinfo);
  const modelID = useSelector((state) => state.profile.modelID);
  const balance = useSelector((state) => state.profile.balance);
  const modelname = useSelector((state) => state.profile.modelname);
  const modelphotolink = useSelector((state) => state.profile.modelphotolink);
  const profilephotolink = useSelector((state) => state.comprofile.photoLink);
  const profilename = useSelector((state) => state.profile.firstname);
  const dispatch = useDispatch();
  const { modelid } = useParams();
  let userid = useSelector((state) => state.register.userID);

  const login = useSelector((state) => state.register.logedin);
  const token = useSelector((state) => state.register.refreshtoken);
  const navigate = useNavigate();
  const ref = useRef(true);
  
  const [MYID, setMYID] = useState(userid);
  const [chatphotolink, setchatphotolink] = useState("");
  const [chatusername, setchatusername] = useState("");
  const [chatfirstname, setfirstname] = useState("");

  const [Chatname, set_Chatname] = useState("");
  const [Chatphoto, set_Chatphoto] = useState(dodoIcon);

  const [showemoji, setemoji] = useState(false);
  const [text, settext] = useState("");
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#0f03fc");

  const [gift_click, setgift_click] = useState(false);
  const [gold_amount, setgold_amount] = useState("50");
  let [sendL, setsendL] = useState(false);
  let [sendcolor, setsend_color] = useState("#f7f5f5");

  const [message, setmessage] = useState([]);

  const messagelist = () => {
    if (loading === false) {
      let ids = modelid.split(",");
      if (message.length > 0) {
        return (
          <ul className="mb-5 bg-black" ref={msgListref}>
            {message.map((value) => {
              if (value.id === userid) {
                if(value.coin){
                 return (<div className="flex justify-end mb-3">
                  <Coinsendview name={"you"} price={value.content} date={value.date}/>
               </div>)
                }else if(!value.coin){
                  return (
                    <div className="flex justify-end mb-3">
                      <Chatreply
                        img={value.photolink}
                        username={value.name}
                        content={value.content}
                        date={value.date}
                        id={value.id}
                        className="bg-orange-500 rounded-xl  hover:bg-orange-300 active:bg-orange-200"
                      />
                    </div>
                  );
                }
                
               
              }else {
                if(value.coin){
                 return( <div className="flex justify-start mb-3">
                     <Coinsendview name={value.name} price={value.content} date={value.date}/>
                  </div>)
                 
                }else if(!value.coin){
                  return (
                    <div className="flex justify-start mb-3">
                      <Chatreply
                        img={value.photolink}
                        username={value.name}
                        content={value.content}
                        date={value.date}
                        id={value.id}
                        className="bg-slate-500 rounded-xl  hover:bg-slate-300 active:bg-slate-200"
                      />
                    </div>
                  );
                }
              }
            
            })}
          </ul>
        
        );
      } else {
        return (
          <div>
            <p className="text-slate-400 text-center mt-40">
              Start Conversation!
            </p>
          </div>
        );
      }
    }
  };

  useEffect(() => {
    //am the client
    if (messagestatus !== "loading") {
      let ids = modelid.split(",");

     if(ids[0] === userid){
      mychat = "yes"
     }
      dispatch(
        getchat({
          token,
          modelid: ids[0],
          clientid: ids[1],
          mychat
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!login) {
      window.location.href = "/";
    }
    if (message.length > 0) {
      const last = msgListref.current.lastElementChild;
      if (last) {
        last.scrollIntoView();
      }
    }

    if (messagestatus === "failed") {
      console.log("failed");
      setLoading(false);
    }

    if (messagestatus === "succeeded") {
      dispatch(changemessagestatus("idle"));
      updateChat();
      setmessage(oldMessage);
      setLoading(false);

      socket.on("LiveChat", (data) => {
        let ids = modelid.split(",")
        if (ids[0] === data.data.fromid && MYID === data.data.toid) {
          // console.log(data)
          dispatch(updatemessage({ date: data.data.date, token }));
          let info = {
            name: data.name,
            photolink: data.photolink,
            content: data.data.content,
            date: data.data.date,
            id: data.data.fromid,
            coin:data.data.coin
          };

          setmessage((value) => [...value, info]);
        }
      });
    }

    if (giftstats === "succeeded") {
     // let sent_text = ` ${gold_amount} Gold success`;
     // send_chat(sent_text);
      setgold_amount("50");
      setgift_click(false);
      setsendL(false);
      dispatch(changemessagestatus("idle"));
    }

    if (giftstats === "failed") {
      toast.error(`${giftmessage}`, { autoClose: 2000 });
      setgift_click(false);
      setsendL(false);
      dispatch(changemessagestatus("idle"));
    }
  }, [message, messagestatus, giftstats]);

  const check_balance = () => {
    let my_balance = parseFloat(balance);
    let send_amount = parseFloat(gold_amount);

    if (my_balance >= send_amount) {
      return true;
    } else {
      return false;
    }
  };

  const send_coin = () => {
    if (check_balance()) {
      let ids = modelid.split(",");
      
      if (giftstats !== "loading") {

        let content = {
          fromid: userid,
          content: `${gold_amount}`,
          toid: ids[0],
          date: Date.now().toString(),
          favourite: false,
          notify: true,
          coin:true,
        };
  
        //let ids = modelid.split(",");
  
        socket.emit("message", content);
        setsendL(true);
        dispatch(
          send_gift({
            token,
            modelid: ids[0],
            userid: userid,
            amount: `${gold_amount}`,
          })
        );

        setgift_click(false);
       
        
     
      let chats = {
        name: chatusername,
        content: content.content,
        date: content.date,
        photolink: chatphotolink,
        id: content.fromid,
        coin:true
      };

      setmessage((value) => [...value, chats]);
      }
    } else {
      //route to topup page
      navigate("/topup");
    }
  };

  const send_chat = (text) => {
    if (text) {
      let reciver = modelid.split(",");
      

      if (userid) {
          setchatphotolink(profilephotolink);
          setchatusername(profilename);
      
      }

    
      let content = {
        fromid: userid,
        content: `${text}`,
        toid: reciver[0],
        date: Date.now().toString(),
        favourite: false,
        notify: true,
        coin:false
      };

      //let ids = modelid.split(",");

      socket.emit("message", content);
      let chats = {
        name: chatusername,
        content: content.content,
        date: content.date,
        photolink: chatphotolink,
        id: content.fromid,
      };

      setmessage((value) => [...value, chats]);
      settext("");
    }
  };

  const updateChat = () => {
    if (chatinfo) {
    
        set_Chatname(chatinfo.name);
        setfirstname(chatinfo.firstname)
        if (chatinfo.photolink) {
          let photo7 = downloadImage(chatinfo.photolink, "profile");
          set_Chatphoto(photo7);
        }
      
    }
  };

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black md:bg-black">
      <div className="chat_nav"><Gennavigation/></div>
      
      <div className='w-full mx-auto md:w-3/5 text-white my-6 p-4 md:mr-auto md:ml-0'>
         {/* Top bar */}
      <div className="fixed top-0 left-0 md:left-40 w-full md:w-2/4 bg-black flex justify-between items-center py-3 px-4 shadow-md z-10">
        <div className="flex items-center gap-2">
          <button className="text-black" onClick={() => navigate(-1)}>
            <img src={backIcon} alt="back" />
            </button>
            <div className="cursor-pointer flex items-center gap-2"  onClick={() => navigate(`/profile/${chatinfo?.id}`)}>


          <img
            src={Chatphoto}
            alt="profile"
            className="rounded-full w-10 h-10 mb-1"
          />
          <div>
            <p className="text-slate-300 font-bold">{chatfirstname}</p>
            <p className="text-slate-300">{chatusername}</p>
          </div>
            </div>
        </div>
        <div className="flex items-center gap-2">
        
          <button
            onClick={(e) => {
              let ids = modelid.split(",");
              if (Chatname) {
                let call = [
                  "caller",
                  `v_id_${ids[0]}`,
                  `v_id_${userid}`,
                  profilename,
                  userid,
                  ids[0]
                ];
                navigate(`/videocall/${call.toString()}`);
              }
            }}
          >
            <img src={videocamIcon} alt="videocall" />
          </button>
          <div>
            <DropdownMenu />
          </div>
        </div>
      </div>

      {/* Scrollable content */}
      <div className="mt-[4rem] mb-[4rem] overflow-y-auto h-[calc(100vh-8rem)] px-4 py-2">
        {loading && (
          <div className="flex flex-col items-center mt-16">
            <PacmanLoader
              color={color}
              loading={loading}
              size={35}
              aria-label="Loading Spinner"
            />
            <p className="text-center text-gray-600 text-sm">Please wait...</p>
          </div>
        )}

        {gift_click && (
          <div className="w-80 h-40 bg-blue-500 z-10 top-1/2 absolute md:left-16 left-4 rounded-lg flex-col flex shadow shadow-black">
            <div className="w-full bg-black flex rounded-tl-lg rounded-tr-lg">
              <button
                className="text-white"
                onClick={() => {
                  send_coin();
                }}
              >
                <img alt="backIcon" src={backIcon}></img>
              </button>

              <p className="text-slate-300 text-center mx-auto text-xs font-bold">
                Gift Gold
              </p>
            </div>

            {sendL && (
              <div className="flex flex-col items-center mt-5">
                <PacmanLoader
                  color={sendcolor}
                  loading={sendL}
                  size={15}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <p className="text-center text-slate-50 text-xs">
                  Giftting... {gold_amount} Gold
                </p>
              </div>
            )}

            <div className="w-full pl-2 pr-2 h-full flex items-center">
              <div className="w-full bg-orange-500 flex flex-col rounded-lg">
                <input
                  type="range"
                  min={50}
                  max={1000}
                  step={50}
                  onChange={(e) => setgold_amount(e.target.value)}
                ></input>

                <div className="w-full flex flex-col items-center">
                  <div className="bg-blue-500 mt-1 mb-1 flex pl-1 pr-1 rounded-lg">
                    <p className="font-bold text-white">{gold_amount}</p>

                    <img
                      alt="goldIcon"
                      src={goldIcon}
                      className="w-5 h-5 object-cover mt-1 ml-1"
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full pl-2 pr-2 flex justify-center pb-1">
              <button
                className="w-full bg-green-500 rounded-2xl p-1 hover:bg-green-400 active:bg-green-300"
                onClick={(e) => send_coin()}
              >
                <p className="text-white text-xs font-bold">Send</p>
              </button>
            </div>
          </div>
        )}

        <div className="mt-40 bg-slate-200 md:bg-black">{messagelist()}</div>

        {showemoji && (
          <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 z-50">
            <emoji-picker
              onClick={(e) => {
                const emoji = e.detail?.emoji?.unicode;
                if (emoji) {
                  settext(`${text}${emoji}`);
                  setemoji(false);
                }
              }}
            />
          </div>
        )}
      </div>

      {/* Gift button */}
      <button
        className="fixed bottom-20 right-4 md:right-[32rem] w-9 h-9"
        onClick={() => {
          setgift_click(!gift_click);
          setgold_amount("50");
        }}
      >
        <img src={goldIcon} alt="img" />
      </button>

      {/* Bottom Bar */}
      <div className="fixed bottom-0 left-0 md:left-16 w-full md:bg-black bg-black z-50 flex justify-between items-center md:justify-center py-3 px-4 shadow-md md:w-2/4">
        <button>
          <img alt="postImageIcon" className="w-10 h-9" src={postimageIcon} />
        </button>
        <div className="flex bg-slate-500 rounded-full pr-2">
          <textarea
            className="bg-slate-500 text-black rounded-l-2xl h-12 pl-3 pr-10 outline-none"
            value={text}
            placeholder="Send message..."
            onChange={(e) => settext(e.target.value)}
          />
          <button onClick={() => setemoji(true)} className="ml-1">
            <img alt="addemoji" src={emojsicon} />
          </button>
        </div>
        <button onClick={() => send_chat(text)}>
          <img alt="sendicon" src={sendIcon} className="w-10 h-9" />
        </button>
      </div>
      </div>
     
      
    </div>
  );
};
