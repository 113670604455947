import React, {useEffect} from "react";
import { FaAngleRight } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Gennavigation } from "../../navs/Gennav";

export const SettingPage = () => {
  const navigate = useNavigate();
  const login = useSelector((state) => state.register.logedin);
  
  useEffect(()=>{
    if (!login) {
      window.location.href = "/";
    }
  },[])

  const navdata = [
    {
      name: "Account",
      icon: <FiUser color="white" size={20} />,
      linktitle: "account",
    },

    {
      name: "Notification",
      icon: <IoMdNotificationsOutline color="white" size={20} />,
      linktitle: "notification",
    },
    {
      name: "Privacy & satefy",
      icon: <AiOutlineSafetyCertificate color="white" size={20} />,
      linktitle: "privacy_satefy",
    },

    {
      name: "Help & Support",
      icon: <IoMdInformationCircleOutline color="white" size={20} />,
      linktitle: "help_support",
    },
  ];
  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
         <div className="chat_nav"><Gennavigation/></div>
      <div className=' w-[90%] mx-auto md:w-3/5 text-white my-6 p-4 md:mr-auto md:ml-0'>
      {/* w-full md:w-2/4 mt-16 px-4 */}
      <header>
        <h4 className="font-bold text-lg text-white">SETTING</h4>
      </header>
      <div className="pt-4">
        {navdata.map((nav, index) => (
          <div
            className="flex justify-between items-center mb-6 "
            key={index}
            onClick={() => navigate(`/${nav.linktitle}`)}
          >
            <div className="flex gap-2 items-center">
              <div>{nav.icon}</div>
              <h4 className="text-lg text-white font-semibold">{nav.name}</h4>
            </div>
            <div>
              <FaAngleRight color="white" />
            </div>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};
