import React, { useState, useEffect } from "react";
import arrowIcon from "../../icons/shaveron.svg";
import helpIcon from "../../icons/help.svg";
import goldIcon from "../../icons/icons8.png";
import dollarIcon from "../../icons/icons8-dollar.png";
import checkIcon from "../../icons/check.svg";
import "../../navs/Navs.css";
import { useNavigate } from "react-router-dom";
import {
  get_my_history,
  ProfilechangeStatus,
} from "../../app/features/profile/profile";
import { useSelector, useDispatch } from "react-redux";
import PacmanLoader from "react-spinners/RingLoader";
import { Gennavigation } from "../../navs/Gennav";


export const History_view = () => {
  const userid = useSelector((state) => state.register.userID);
  const Mymodel = useSelector((state) => state.profile.modelID);
  const historys = useSelector((state) => state.profile.historys);
  const history_stats = useSelector((state) => state.profile.history_stats);
  const history_message = useSelector((state) => state.profile.history_message);
  const token = useSelector((state) => state.register.refreshtoken);
  const login = useSelector((state) => state.register.logedin);
  const [click,setclick] = useState(true)

  const formatter = new Intl.NumberFormat("en-US");

  const [help_click, sethelp_click] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#FFFFFF");

  let coin;
  let usd;
  let request;
  let earning;
  let gift;
  let like;

  useEffect(() => {
    if (!login) {
      window.location.href = "/";
      // navigate('/', {replace:true})
    }
    get_History();
  }, []);

  useEffect(() => {
    if (history_stats === "succeeded") {
      console.log("my account history " + historys);
      dispatch(ProfilechangeStatus("idle"));
      setLoading(false);
    }

    if (history_stats === "failed") {
      console.log("history view " + history_message);
      dispatch(ProfilechangeStatus("idle"));
      setLoading(false);
    }
  }, [history_stats]);

  const get_History = () => {
    if (history_stats !== "loading") {
      dispatch(
        get_my_history({
          token,
          userid,
          modelid: Mymodel,
        })
      );
    }
  };

  const setHistoty = () => {
    if (loading === false) {
      if (parseFloat(historys.coin) > 0) {
        coin = formatter.format(Number(historys.coin));
      } else {
        coin = 0;
      }

      if (parseFloat(historys.usd) > 0) {
        usd = formatter.format(Number(historys.usd));
      } else {
        usd = 0;
      }

      if (parseFloat(historys.request) > 0) {
        request = formatter.format(Number(historys.request));
      } else {
        request = "0";
      }

      if (parseFloat(historys.earning) > 0) {
        earning = formatter.format(Number(historys.earning));
      } else {
        earning = "0";
      }

      if (parseFloat(historys.gift) > 0) {
        gift = formatter.format(Number(historys.gift));
      } else {
        gift = "0";
      }

      if (parseFloat(historys.like) > 0) {
        like = formatter.format(Number(historys.like));
      } else {
        like = "0";
      }
      return true;
    } else {
      return false;
    }
  };

  const checkwithdraw = (usd)=>{
    if(parseFloat(usd) > 50 ){
      return usd
    }else{
      return 0
    }
    
  }

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black">
      <div className="chat_nav"><Gennavigation/></div>
       <div className='w-full md:w-3/5 text-white my-6 p-4 md:mr-auto' onClick={(e)=>setclick(true)}>
      <div className="w-full  h-screen bg-gradient-to-t from-orange-200  to-[#F97316] flex flex-col ">
        {loading && (
          <div className="flex w-full mt-16 flex-col items-center">
            <PacmanLoader
              color={color}
              loading={loading}
              size={35}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p className="text-sm text-white">Loading...</p>
          </div>
        )}

        {setHistoty() && (
          <div className="w-full h-full items-center pl-2 pr-2 flex flex-col">
            <p className="font-semibold text-white text-center text-sm">
              My Earnings
            </p>
            <div className="bg-slate-100 ml-5 mr-5 h-24 mb-3 mt-2 w-full pr-2 pl-2 rounded-md">
              <div className="flex justify-between">
                <button
                  className="flex mt-2"
                  onClick={(e) => {
                    navigate("/earning");
                  }}
                >
                  <p className="font-serif text-slate-500">Gold</p>
                  <img
                    alt="arrow"
                    src={arrowIcon}
                    className="w-5 h-5 object-cover"
                  ></img>
                </button>

                <button onClick={(e) => sethelp_click(!help_click)}>
                  <img alt="help" src={helpIcon}></img>
                </button>
              </div>

              <div className="flex mt-1 flex-col ">
                <div className="flex">
                  <img
                    alt="goldicon"
                    src={goldIcon}
                    className="h-5 w-5 object-cover mt-1"
                  ></img>
                  <p className="text-black font-bold text-xl ml-1 ">{`${coin}`}</p>
                </div>

                <div className="">
                  <p className="font-semibold mb-5">= ${usd}</p>
                </div>
              </div>
            </div>

            <div className="bg-slate-100 ml-5 mr-5 h-20 mb-3 mt-2 w-full pr-2 pl-2 rounded-md">
              <p className="font-semibold text-sm text-[#F97316]">
                {" "}
                withdrawable money
              </p>

              <div className="w-full flex justify-between">
                <div className="flex">
                  <p className="text-sm">
                    $<span className="text-xl font-bold">{checkwithdraw(usd)}</span>
                  </p>
                  <img
                    alt="dollaricon"
                    src={dollarIcon}
                    className="w-5 h-5 object-cover mt-1 ml-1"
                  ></img>
                </div>

                <button className="bg-[#F97316] p-2 rounded-2xl active:bg-[#F9731670] hover:bg-[#F97316a7]">
                  <p className="text-slate-50 text-xs">withdraw</p>
                </button>
              </div>
            </div>

            <div className="bg-slate-100 ml-5 mr-5 h-1/3 mb-3 mt-2 w-full pr-2 pl-2 rounded-md">
              <div className="flex justify-between">
                <p className="font-bold mb-4 text-[#F97316]">Account analytics</p>
                <p className=" text-[#F97316] text-sm">Last 28 days</p>
              </div>

              <div className="flex pl-3 pr-3  justify-center">
                <div className="w-1/2 h-20  bg-slate-500 flex flex-col rounded-md pl-3 mr-2 hover:bg-slate-400 active:bg-slate-300">
                  <p className="text-white text-xs">Appointments</p>
                  <div className="flex items-center h-full">
                    <p className="text-white font-bold text-xl">{request}</p>
                    <img
                      alt="checkIcon"
                      src={checkIcon}
                      className="ml-1 "
                    ></img>
                  </div>
                </div>

                <div className="w-1/2 h-20  bg-slate-500 flex flex-col rounded-md pl-3 ml-2  hover:bg-slate-400 active:bg-slate-300">
                  <p className="text-white text-xs">Earnings</p>
                  <div className="flex items-center h-full">
                    <p className="text-white font-bold text-xl">${earning}</p>
                    <img
                      alt="checkIcon"
                      src={checkIcon}
                      className="ml-1 "
                    ></img>
                  </div>
                </div>
              </div>

              <div className="flex pl-3 pr-3 mt-3 justify-center">
                <div className="w-1/2 h-20  bg-slate-500 flex flex-col rounded-md pl-3 mr-2 hover:bg-slate-400 active:bg-slate-300">
                  <p className="text-white text-xs">Gitfs</p>
                  <div className="flex items-center h-full">
                    <p className="text-white font-bold text-xl">{gift}</p>
                    <img
                      alt="checkIcon"
                      src={checkIcon}
                      className="ml-1 "
                    ></img>
                  </div>
                </div>

                <div className="w-1/2 h-20  bg-slate-500 flex flex-col rounded-md pl-3 ml-2  hover:bg-slate-400 active:bg-slate-300">
                  <p className="text-white text-xs">Likes</p>
                  <div className="flex items-center h-full">
                    <p className="text-white font-bold text-xl">{like}</p>
                    <img
                      alt="checkIcon"
                      src={checkIcon}
                      className="ml-1 "
                    ></img>
                  </div>
                </div>
              </div>
            </div>

            {help_click && (
              <div
                className="bg-slate-100 left-5 right-5 h-40 mb-3 mt-2  pr-2 pl-2 rounded-md
                    z-20 top-1/4 absolute shadow shadow-black note
                    "
              >
                <p className="text-center font-bold text-[#000000]">
                  Instruction
                </p>

                <div className="flex w-full items-center flex-col">
                  <div className="flex mt-2">
                    <img
                      alt="coinImg"
                      src={goldIcon}
                      className="w-6 h-6 object-cover mt-2"
                    ></img>
                    <p className="font-bold text-[#ca03fc] text-2xl">
                      1 = 0.05 cents
                    </p>
                  </div>

                  <div>
                    <p className="text-center text-[#F97316] mt-2">
                      Minimum withdraw = $50
                    </p>
                  </div>

                  <button
                    className="bg-[#F97316] pt-3 pb-3 pl-7 pr-7 mt-8 rounded-2xl active:bg-[#F9731670] hover:bg-[#F97316a7]"
                    onClick={(e) => sethelp_click(false)}
                  >
                    <p className="text-slate-50 text-xs font-bold">Got it</p>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      </div>
    </div>
  );
};
