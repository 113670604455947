import React, { useState, useEffect } from "react";
import PacmanLoader from "react-spinners/RingLoader";
import { useSelector, useDispatch } from "react-redux";
import { getall_request } from "../../../app/features/booking/booking";
import { Requestlist } from "./modelnotifylist/Requestlist";
import { Acceptedlist } from "./modelnotifylist/Acceptedlist";
import { Meetuplist } from "./modelnotifylist/Meetuplist";
import { List_of_message } from "../../users/List_of_message";
import { Gennavigation } from "../../../navs/Gennav";

export const Allview = () => {
  const [loading, setloading] = useState(true);
  const [success, setsuccess] = useState(false);
  let [color, setColor] = useState("#c2d0e1");
  const userid = useSelector((state) => state.register.userID);
  const token = useSelector((state) => state.register.refreshtoken);
  const Allrequest = useSelector((state) => state.booking.Allrequest);
  const allrequest_stats = useSelector(
    (state) => state.booking.allrequest_stats
  );
  const dispatch = useDispatch();
  const Mymodel = useSelector((state) => state.profile.modelID);
  var latter;

  useEffect(() => {
    fetchall_req();
  }, []);

  useEffect(() => {
    if (allrequest_stats === "succeeded") {
      setloading(false);
    }
    if (allrequest_stats === "failed") {
      setloading(false);
    }
  }, [allrequest_stats]);

  const getallrequest = () => {
    if (loading === false) {
      if (Allrequest.length > 0) {
        return Allrequest.map((value,index) => {
          if (
            (value.modelid !== Mymodel && value.status === "accepted") ||
            value.status === "decline"
          ) {
            return (
              <ul className="flex flex-col items-center pl-2 pr-2 w-full mb-1">
                <Acceptedlist
                key={`${index}_${userid}`}
                  name={value.name}
                  photolink={value.photolink}
                  status={value.status}
                  type={value.type}
                  date={value.date}
                  time={value.time}
                  modelid={value.modelid}
                  modeluserid={value.modeluserid}
                  amount={value.amount}
                />
              </ul>
            );
          }

          if (value.status === "pending" && value.modelid !== Mymodel) {
            return (
              <ul className="flex flex-col items-center pl-2 pr-2 w-full mb-1">
                <Requestlist
                  photolink={value.photolink}
                  modelname={value.name}
                  modeltype={value.type}
                  date={value.date}
                  time={value.time}
                  modelid={value.modelid}
                />
              </ul>
            );
          }

          if (
            value.status === "pending" ||
            (value.status === "accepted" && value.modelid === Mymodel)
          ) {
            let messaging = check_status(value.status, value.type, value.name);
            return (
              <ul className="flex flex-col items-center pl-2 pr-2 w-full mb-1">
                <Meetuplist
                  clientname={value.name}
                  type={value.type}
                  photolink={value.photolink}
                  date={value.date}
                  time={value.time}
                  venue={value.place}
                  postuserid={value.clientid}
                  modelid={value.modelid}
                  status={value.status}
                  latter1={messaging}
                />
              </ul>
            );
          }

          if (value.ismessage) {
            return (
              <ul className="flex flex-col items-center pl-2 pr-2">
                <List_of_message id={value.id} time={value.time} message={value.message} Mymodel={Mymodel} />
              </ul>
            );
          }
        });
      } else {
        return (
          <div className="w-full h-full flex justify-center items-center mt-16">
            <p className="text-slate-400  text-xs">
              Notifications about your account will appear here.
            </p>
          </div>
        );
      }
    }
  };

  const fetchall_req = () => {
    if (allrequest_stats !== "loading") {
      dispatch(getall_request({ userid, token, modelid: Mymodel }));
    }
  };

  const check_status = (status, type, clientname) => {
    if (status === "accepted") {
      return (latter = `you accepted a ${type} request with ${clientname}`);
    } else {
      return (latter = `${clientname} Reqested a ${type} with you`);
    }
  };

  return (
    <div className="w-full ">
      <div className="w-full  flex flex-col mt-2 mb-5">
        {loading && (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <PacmanLoader
              color={color}
              loading={loading}
              size={35}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="text-slate-400 text-xs">please wait...</p>
          </div>
        )}

        <div className="w-full flex flex-col pb-7 overflow-auto mb-3">

           {getallrequest()}

        </div>
      </div>
    </div>
  );
};
