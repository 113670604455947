import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { UserList } from "./UserList";
import PacmanLoader from "react-spinners/DotLoader";
import { getsearch } from "../../app/features/profile/profile";
import { useSelector, useDispatch } from "react-redux";
import { ProfilechangeStatus } from "../../app/features/profile/profile";
import { Gennavigation } from "../../navs/Gennav";

const SearchBar = () => {
  const [list_of_user, setlist_of_user] = useState([])
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#0f03fc");
  const searchstats = useSelector((state) => state.profile.searchstats);
  const search_users = useSelector((state) => state.profile.search_users);
  const dispatch = useDispatch()

  const showuser = ()=>{

    if(loading === false){
      if(list_of_user.length > 0){
        return list_of_user.map((value,index)=>{
         return (
           <UserList index={`${value.index}_${index}`} photolink={value.photolink} name={value.name} country={value.country} gender={value.gender} age={value.age} userid={value.userid} nickname={value.nickname} />
         )
        })
       }else{
         return (
          <p className="text-yellow-400 font-bold text-center">
           User Not Found !!
          </p>
         )
       }
    }
    
  }

  useEffect(()=>{
    if(searchstats !== "loading"){
      setLoading(true)
      dispatch(getsearch())
    }
  },[])

  useEffect(()=>{
    if(searchstats === "succeeded"){
      setlist_of_user(search_users)
      setLoading(false)
      dispatch(ProfilechangeStatus("idle"))
    }
  },[searchstats])

  return (
      <div className="mb-3 w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black ">
         <div className="chat_nav"><Gennavigation/></div>
      <div className="relative mt-6 m-auto w-[90%] sm:w-[60%] items-center sm:m-0">
        <FaSearch className="absolute top-4 left-4 text-gray-400" />
        <input
          type="text"
          placeholder="Search..."
          className="w-full bg-gray-800 text-white py-3 px-12 rounded-md focus:outline-none m-auto"
          onInput={(e)=>{
            if(e.currentTarget.value.length <= 0){
              setlist_of_user(search_users)
              
            }

            if(e.currentTarget.value.length > 0){
              
              setlist_of_user(search_users.filter(value=> {
                let firstname = value.name.split(" ")
                return value.nickname.toLowerCase() === e.currentTarget.value.toLowerCase() || firstname[0].toLowerCase() === e.currentTarget.value.toLowerCase()  || firstname[1].toLowerCase() === e.currentTarget.value.toLowerCase() || value.name.toLowerCase() === e.currentTarget.value.toLowerCase() || value.country.toLowerCase() === e.currentTarget.value.toLowerCase()
              } ))

            }


          }}
        />

       {loading && (
          <div className="flex flex-col items-center mt-16">
            <PacmanLoader
              color={color}
              loading={loading}
              size={35}
              aria-label="Loading Spinner"
            />
            <p className="text-center text-gray-600 text-sm">Fetching users...</p>
          </div>
        )}


        <ul className="mt-3 flex flex-col">
         {showuser()}
        </ul>
      </div>

    </div>
  );
};

export default SearchBar;