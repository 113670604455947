import { Gennav } from "./navs/Gennav";
import notificationIcon from "./icons/notificationIcon.svg";
import { Register } from "./auth/register";
import { Compregis } from "./auth/Compregis";
import messageIcon from "./icons/messageIcon.png";
import { Home } from "./views/Home";
import { ChangePassword } from "./auth/ChangePassword";
import { BottomNav } from "./navs/BottomNav";
import { Commmentpage } from "./views/homeviews/Commmentpage";
import { PostImage } from "./views/homeviews/postImage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Postvideo } from "./views/homeviews/Postvideo";
import { Profile } from "./views/Profile";
import { CreateModelview } from "./views/modelviews/CreateModelview";
import { Model } from "./views/modelviews/Model";
import { Modelbyid } from "./views/modelviews/Modelbyid";
import { Editmodel } from "./views/modelviews/Editmodel";
import { ViewIDcard } from "./views/modelviews/confirmhostAdmin/ViewIDcard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Chat } from "./views/messageview/Chat";
import { MessageView } from "./views/messageview/MessageView";
import { socket } from "./api/config";
import { getmessagenotication } from "./app/features/message/messageSlice";
import { Modelnotify } from "./views/modelviews/Modelnotify";
import { notifymodel, resetstat } from "./app/features/booking/booking";
import { Earnings } from "./views/history/Earnings";
import { History_view } from "./views/history/History_view";
import { Topup } from "./views/homeviews/Topup";
import { Fail } from "./fragment/Fail";
import { Success } from "./fragment/Success";
import { Users } from "./views/users/Users";
import { Message_users } from "./views/users/Message_users";
import { Verify_models } from "./views/homeviews/Verify_models";
import { Edit_profile } from "./views/Edit_profile";
import { UserMessage } from "./views/users/UserMessage";
import { Collections } from "./views/collectionviews/Collections";
import { VideoCallPage } from "./views/messageview/VideoCall";
import { Add_exclusive } from "./views/profileview/Add_exclusive";
import SearchBar from "./views/searchviews/searchBar";
import Feedback from "./views/Feedbackview/feedback";
import Community from "./views/settingviews/Community";
import PrivacyPolicy from "./views/settingviews/PrivacyPolicy";
import "./navs/Navs.css"
import {
  set_calling,
  set_offer,
  set_sdpcall,
  set_reject_answer,
} from "./app/features/message/messageSlice";
import { useState } from "react";
import { Speaktohelp } from "./views/settingviews/Speaktohelp";

import {
  set_videocall_data,
  set_videocall_message,
} from "./app/features/message/messageSlice";
import { VerifiedUserForm } from "./views/profileview/VerificationForm";
import { VerificationPage } from "./views/profileview/verificationPage";
import { SettingPage } from "./views/settingviews/Setting";
import { AccountPage } from "./views/settingviews/Account";
import { AccountinfoPage } from "./views/settingviews/Accountinfo";
import { ChangePasswordPage } from "./views/settingviews/changepassword";
import { DeleteaccountPage } from "./views/settingviews/Deleteaccount";
import { NotificationPage } from "./views/settingviews/Notification";
import { PushNotificationPage } from "./views/settingviews/Pushnotification";
import { PrivacySafetyPage } from "./views/settingviews/Privacy_safety";
import { HelpSupportPage } from "./views/settingviews/Help_support";
import { FollowerPage } from "./views/followerview/following";
import { Really_Help_Support_Page } from "./views/helpSupportview/helpsupport";
import { AboutPage } from "./views/helpSupportview/about";
import { FansPage } from "./views/helpSupportview/fans";
import { Creators } from "./views/helpSupportview/creator";
import { GeneralPage } from "./views/helpSupportview/general";
import { AboutMmekoPage } from "./views/helpSupportview/aboutmmeko";
import {PrivateCallPage} from "./views/messageview/PrivateCall"
import { set_reject_call, add_call_data } from "./app/features/booking/booking";
import { Emailnotification } from "./views/settingviews/Emailnotification";
import { Blockusers } from "./views/settingviews/Blockusers";
import { setlastnote, setmessagelastnote } from "./app/features/profile/profile";
import { init } from "./api/InistiacenewNote";
import AnalyticsTracker from "./AnalyticsTracker";
import { useCall } from "./views/messageview/context";
import { Contentcreators } from "./views/helpSupportview/About/Contentcreators";
import { DoesMmekowork } from "./views/helpSupportview/About/DoesMmekowork";
import { Explicitcontent } from "./views/helpSupportview/About/Explicitcontent";
import { Whoismmeko } from "./views/helpSupportview/About/Whoismmeko";
import { Whouse } from "./views/helpSupportview/About/Whouse";
import { Becomeamodel } from "./views/helpSupportview/Creator/Becomeamodel";
import { Contentnotallow } from "./views/helpSupportview/Creator/Contentnotallow";
import { Fanmeetanddate } from "./views/helpSupportview/Creator/Fanmeetanddate";
import { Makeyourfirst } from "./views/helpSupportview/Creator/Makeyourfirst";
import { Modelaccount } from "./views/helpSupportview/Creator/Modelaccount";
import { Outdoorcontent } from "./views/helpSupportview/Creator/Outdoorcontent";
import { Payout } from "./views/helpSupportview/Creator/Payout";
import { Privateshowwork } from "./views/helpSupportview/Creator/Privateshowwork";
import { ReceivePayout } from "./views/helpSupportview/Creator/ReceivePayout";
import { Revenueshare } from "./views/helpSupportview/Creator/Revenueshare";
import { Whathappen } from "./views/helpSupportview/Creator/Whathappen";
import { Datemeetandfanwork } from "./views/helpSupportview/Fan/Datemeetandfanwork";
import { Howdoprivate } from "./views/helpSupportview/Fan/Howdoprivate";
import { Purchasegold } from "./views/helpSupportview/Fan/Purchasegold";
import { Refound } from "./views/helpSupportview/Fan/Refound";
import { Buygold } from "./views/helpSupportview/Accounts/Buygold";
import { Converterate } from "./views/helpSupportview/Accounts/Converterate";



let take = true;
let take2 = true;
let take3 = true;

function App() {
  const login = useSelector((state) => state.register.logedin);
  const userid = useSelector((state) => state.register.userID);
  let pushnote = useSelector((state) => state.profile.pushnote);
  const mymessagenotifystatus = useSelector(
    (state) => state.message.mymessagenotifystatus
  );

  const notifystats = useSelector((state) => state.booking.notifystats);
  const dispatch = useDispatch();
  const { closeOption, toggleoption , opening,closehomeopts} = useCall()

 

  useEffect(() => {
    if (login) {
      if (userid) {
        socket.emit("online", userid);

        socket.on(`v_id_${userid}`, (data) => {
          let my_id = `v_id_${userid}`;

          if (data.data.caller_id === my_id) {
            if (data.data.answer_message === "reject") {
              console.log("rejecting a call");
              dispatch(set_reject_answer(data.data.answer_message));
            }

            console.log("offer message " + data.data.answer_message);
            set_videocall_message(data.data.message);
            set_videocall_data(data.data);
          }

          if (my_id === data.data.answer_id) {
            dispatch(set_videocall_message(data.data.message));
            dispatch(set_videocall_data(data.data));

            if (data.data.answer_message === "calling") {
              if (data.data) {
                if (data.data.sdp_c_offer) {
                  dispatch(set_sdpcall(data.data.sdp_c_offer));
                }

                if (data.data.offer_can) {
                  dispatch(set_offer(data.data.offer_can));
                }
              }
              dispatch(set_calling(true));
            }

            if (data.data.caller_id === my_id) {
              set_videocall_message(data.message);
              set_videocall_data(data.data.answer_message);
            }

            if (my_id === data.data.answer_id) {
              dispatch(set_videocall_message(data.data.message));
              dispatch(set_videocall_data(data.data));
              //console.log("offcan " + data.data.offer_can);
              if (data.data.answer_message === "calling") {
                console.log(data.data.message);
                dispatch(set_calling(true));
              }

              if (data.data.answer_message === "reject") {
                //console.log("rejecting a call");
                dispatch(set_calling(false));
              }
            }
          }
        });

        socket.on(`pvc_${userid}`, (data) => {
          let my_id = `pvc_${userid}`;

          if (data.data.caller_id === my_id) {
            if (data.data.answer_message === "reject") {
              console.log("rejecting a call");
              dispatch(set_reject_call("reject"));
            }

            console.log("offer message " + data.data.answer_message);
            set_videocall_message(data.data.message);
            set_videocall_data(data.data);
          }

         
            if (my_id === data.data.answer_id) {
              let callData = [data.data.caller_id, data.data.answer_id, data.data.toid, data.data.fromid, "answer" ]
              
              dispatch(add_call_data(callData));
              dispatch(set_videocall_message(data.data.message));
              dispatch(set_videocall_data(data.data));
              // dispatch(set_videocall_data(data.data));
              //console.log("offcan " + data.data.offer_can);
              if (data.data.answer_message === "calling") {
                //console.log(data.data.message);
                dispatch(set_calling(true));
                if (data.data) {
                  if (data.data.sdp_c_offer) {
                    dispatch(set_sdpcall(data.data.sdp_c_offer));
                  }
  
                  if (data.data.offer_can) {
                    dispatch(set_offer(data.data.offer_can));
                  }
                }
              }

              if (data.data.answer_message === "reject") {
                //console.log("rejecting a call");
                dispatch(set_reject_call("reject"));
                dispatch(set_calling(false));
              }
            }
          
        });
      }

     
    }

  
  }, [login]);

  useEffect(() => {

    if (userid) {
    
      if (take2 === true) {
        setInterval(() => {
        // console.log("inside model notification " + modelID);
          if (notifystats !== "loading") {
            console.log("model notification")
            dispatch(notifymodel({userid }));
          
          }
        }, 20000);

        take2 = false;
      }
    }
  },[userid]);

  useEffect(() => {
   
    window.addEventListener("blur",()=>{
      if (userid) {
        if (take === true) {
          setInterval(() => {
            // console.log("interval before userid");
  
            // console.log("inside Interval");
            if (
              mymessagenotifystatus === "idle" &&
              mymessagenotifystatus !== "loading"
            ) {
              console.log("inside notification");
              dispatch(getmessagenotication({ userid: userid }));
            }
          }, 20000);
          take = false;
        }
      }
      })

    window.addEventListener("focus",()=>{
      if (userid) {
        if (take === true) {
          setInterval(() => {
            // console.log("interval before userid");
  
            // console.log("inside Interval");
            if (
              mymessagenotifystatus === "idle" &&
              mymessagenotifystatus !== "loading"
            ) {
              console.log("inside notification");
              dispatch(getmessagenotication({ userid: userid }));
            }
          }, 20000);
          take = false;
        }
      }
    })

   
    
  },[userid]);

  useEffect(() => {
   
      if (userid) {
        if (pushnote === true) {
          let fetchapi = async()=>{
            await init(userid)
          }

          fetchapi()
        
        }
      }

    
  },[userid]);





  return (
    <div className=" bg-black w-full box-content m-0 p-0 overflow-hidden">
      <Router>
        <AnalyticsTracker/>
        {/* <Gennav/> */}
      
        <div className="bg-black relative md:w-screen h-screen md:flex md:flex-row md:justify-center pb-8 md:ml-20 overflow-auto allbody" onClick={(e)=>{
          closehomeopts()
          closeOption()
        }}>  
          <Routes>
            <Route index element={<Home/>} />
            <Route exact path="home" element={<Home />} />
            <Route exact path="uploadprofile/:userID" element={<Compregis />} />
            <Route
              exact
              path="newpassword/:userID"
              element={<ChangePassword />}
            />
            <Route exact path="register" element={<Register />} />
            <Route exact path="comment" element={<Commmentpage />} />
            <Route exact path="postimage" element={<PostImage />} />
            <Route exact path="postvideo" element={<Postvideo />} />
            <Route exact path="profile/:postuserid" element={<Profile />} />
            <Route exact path="feedback" element={<Feedback/>} />
            <Route exact path="createmodel" element={<CreateModelview />} />
            <Route exact path="model" element={<Model />} />
            <Route exact path="modelbyid/:modelID" element={<Modelbyid />} />
            <Route exact path="editmodel" element={<Editmodel />} />
            <Route exact path="idcard/:id" element={<ViewIDcard />} />
            <Route exact path="message/:modelid" element={<Chat />} />
            <Route exact path="allmessage/*" element={<MessageView />} />
            <Route exact path="search" element={<SearchBar />} />
            <Route exact path="modelnotify/*" element={<Modelnotify />} />
            <Route exact path="/community" element={<Community />} />
            <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route exact path="history" element={<History_view />} />
            <Route exact path="earning" element={<Earnings />} />
            <Route exact path="topup" element={<Topup />} />
            <Route exact path="payment-sucess" element={<Success />} />
            <Route exact path="payment-fail" element={<Fail />} />
            <Route exact path="verifymodel" element={<Verify_models />} />
            <Route exact path="editprofile" element={<Edit_profile />} />
            <Route exact path="viewusers" element={<Users />} />
            <Route exact path="messageusers" element={<Message_users />} />
            <Route exact path="mymessage" element={<UserMessage />} />
            <Route exact path="collections/*" element={<Collections />} />
            <Route exact path="videocall/:call" element={<VideoCallPage />} />
            <Route exact path="privatecall" element={<PrivateCallPage />} />
            <Route exact path="verify" element={<VerifiedUserForm />} />
            <Route exact path="setting" element={<SettingPage />} />
            <Route exact path="account" element={<AccountPage />} />
            <Route exact path="accountinfo" element={<AccountinfoPage />} />
            <Route exact path="deleteaccount" element={<DeleteaccountPage />} />
            <Route exact path="notification" element={<NotificationPage />} />
            <Route exact path="help_support" element={<HelpSupportPage />} />
            <Route exact path="verification" element={<VerificationPage />} />
            <Route exact path="follower" element={<FollowerPage />} />
            <Route exact path="help" element={<Really_Help_Support_Page />} />
            <Route exact path="about" element={<AboutPage />} />
            <Route exact path="fans" element={<FansPage />} />
            <Route exact path="creator" element={<Creators />} />
            <Route exact path="general" element={<GeneralPage />} />
            <Route exact path="addexclusive" element={<Add_exclusive />} />
            <Route exact path="account_login" element={<AccountPage />} />
            <Route exact path="aboutmmeko" element={<AboutMmekoPage />} />
            <Route exact path="Whoismmeko" element={<Whoismmeko />} />
            <Route exact path="Contentcreators" element={<Contentcreators />} />
            <Route exact path="Explicitcontent" element={<Explicitcontent />} />
            <Route exact path="DoesMmekowork" element={<DoesMmekowork />} />
            <Route exact path="Whouse" element={<Whouse />} />
            <Route exact path="Becomeamodel" element={<Becomeamodel />} />
            <Route exact path="Modelaccount" element={<Modelaccount />} />
            <Route exact path="Makeyourfirst" element={<Makeyourfirst />} />
            <Route exact path="Privateshowwork" element={<Privateshowwork />} />
            <Route exact path="Fanmeetanddate" element={<Fanmeetanddate />} />
            <Route exact path="Whathappen" element={<Whathappen />} />
            <Route exact path="Payout" element={<Payout />} />
            <Route exact path="Revenueshare" element={<Revenueshare />} />
            <Route exact path="ReceivePayout" element={<ReceivePayout />} />
            <Route exact path="Outdoorcontent" element={<Outdoorcontent />} />
            <Route exact path="Contentnotallow" element={<Contentnotallow />} />
            <Route exact path="Purchasegold" element={<Purchasegold />} />
            <Route exact path="Howdoprivate" element={<Howdoprivate />} />
            <Route exact path="Datemeetandfanwork" element={<Datemeetandfanwork />} />
            <Route exact path="Refound" element={<Refound />} />
            <Route exact path="Buygold" element={<Buygold />} />
            <Route exact path="Converterate" element={<Converterate />} />
            


            <Route
              exact
              path="privacy_satefy"
              element={<PrivacySafetyPage />}
            />
            <Route
              exact
              path="pushnotification"
              element={<PushNotificationPage />}
            />
             <Route
              exact
              path="emailnotification"
              element={<Emailnotification />}
            />
            <Route
              exact
              path="changepassword"
              element={<ChangePasswordPage />}
            />

            <Route
              exact
              path="blockedusers"
              element={<Blockusers />}
            />

<Route
              exact
              path="speaker"
              element={<Speaktohelp />}
            />
          </Routes>
        </div>
        <div className="">
          
        <BottomNav />
          </div>
      </Router>
    </div>
  );
}

export default App;