import React, { useState } from "react";

import Positive from '../../../src/icons/positive.png'
import Negative from '../../../src/icons/negative.png'
import Neutral from '../../../src/icons/neutral.png'
import { useNavigate } from "react-router-dom";
import { Gennavigation } from "../../navs/Gennav";

const Feedback = () => {
  const [selectedRating, setSelectedRating] = useState(null);
  const [comment, setComment] = useState("");
  const [visitFrequency, setVisitFrequency] = useState("");
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()


  const ratings = [
    { label: "Positive", src: Positive },
    { label: "Neutral", src: Neutral },
    { label: "Negative", src:  Negative },
  ];

  const handleSubmit = () => {
    console.log({ selectedRating, comment, visitFrequency });
    setShowModal(true);
    setTimeout(() => {
     navigate('/');
    }, 2000);
  };

  return (
    <div className="w-screen sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 mx-auto bg-black md:mx-left">
          <div className="chat_nav"><Gennavigation/></div>
     
      <div className="w-[90%] mx-auto md:w-3/5 text-white my-6 p-4 md:mr-auto md:ml-0">
       
        <h1 className="text-orange-600 font-bold text-2xl">Mmeko Feedback</h1>

        {/* Question 1 */}
        <p className="mt-4 text-lg font-semibold">
          1. Overall, how was our service today?
        </p>
        <div className="mt-4 grid grid-cols-1 gap-4">
          {ratings.map((rating, index) => (
            <button
              key={index}
              className={`border rounded-lg p-4 flex flex-col items-center transition-all ${
                selectedRating === rating.label
                  ? "border-orange-500"
                  : "border-gray-300"
              }`}
              onClick={() => setSelectedRating(rating.label)}
            >
              <img src={rating.src} alt={rating.label} className="w-32 h-32" />
              <p className="mt-2">{rating.label}</p>
            </button>
          ))}
        </div>

        {/* Question 2 */}
        <p className="mt-6 text-lg font-semibold">2. Any comments on your rating?</p>
        <textarea
          className="w-full border rounded-lg p-2 mt-2 text-gray-800"
          rows="3"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Write your comment here..."
        ></textarea>

        {/* Question 3 */}
        <p className="mt-6 text-lg font-semibold">
          3. How often do you visit Mmeko?
        </p>
        <div className="mt-2 space-y-2">
          {["Daily", "Weekly", "Monthly", "First time"].map((option) => (
            <label key={option} className="flex items-center gap-2">
              <input
                type="radio"
                name="visitFrequency"
                value={option}
                checked={visitFrequency === option}
                onChange={(e) => setVisitFrequency(e.target.value)}
                className="form-radio"
              />
              {option}
            </label>
          ))}
        </div>

      
        <button
          onClick={handleSubmit}
          className="mt-6 w-full bg-orange-600 text-white py-2 rounded-lg hover:bg-orange-700 transition"
        >
          Done
        </button>
          </div>
          {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center md:justify-start w-[90%] mx-auto md:w-4/5 ">
          <div className="bg-white p-6 rounded-lg shadow-lg md:ml-40">
            <p className="text-center font-semibold text-orange-500">Your review has been sent</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
